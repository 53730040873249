import { useState, useEffect } from 'react'

import TextField from '@mui/material/TextField'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'

import dayjs from 'dayjs'

const PickerDateTime = ({ initialValue, onChange, dateFormat, readOnly=false }) => {
    const [value, setValue] = useState()

    useEffect(() => {
        if (initialValue === '') initialValue = null;
        setValue(dayjs(initialValue))
    }, [])

    return (
        <div className="picker-date-container">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                    value={value}
                    disabled={readOnly}
                    slotProps={{ textField: { placeholder: 'Select Date & Time' } }}
                    onChange={(newValue) => {
                        setValue(newValue)

                        onChange(dateFormat ? dayjs(newValue).format(dateFormat) : newValue)
                    }}
                />
            </LocalizationProvider>
        </div>
    );
}

export default PickerDateTime