import { FileTypes } from '../../../sys/constants/enums';

export function getGlobalFormFields(ac, dataGlobal, data) {
  const proofingServiceEnabled = ac.AuthHelper.checkFeature('ProofingService');
    console.log(proofingServiceEnabled);

  return [
    {
      type: 'input-file',
      name: 'watermark',
      label: 'Watermark',
      label_info: 'This will be used for proofing',
      acceptedFileTypes: FileTypes.IMAGES,
      imageUrl: dataGlobal?.watermark_url,
      condition: (_) => { console.log('cond', proofingServiceEnabled); return proofingServiceEnabled; }
    },
    {
      type: 'input-text',
      name: 'policy_description',
      label: 'Policy: Description'
    },
    {
      type: 'input-text',
      name: 'policy_url',
      label: 'Policy: URL'
    },
    {
      type: 'input-text',
      name: 'ga_tracking_id',
      label: 'Google Analytics Tracking ID',
      description: (
        <a href="https://analytics.google.com/analytics/web/" target="_blank">
          Google Analytics Documentation
        </a>
      )
    },
    {
      type: 'input-text',
      name: 'gtm_container_id',
      label: 'Google Tag Manager Container ID',
      description: (
        <a
          href="https://developers.google.com/tag-manager/quicksatart"
          target="_blank"
        >
          Google Tag Manager Documentation
        </a>
      )
    },
    {
      type: 'input-text',
      name: 'email_friendly_from',
      label: 'Friendly From Name for Email'
    },
    {
      type: 'input-text',
      name: 'customer_service_email',
      label: 'Customer Service Email',
      label_info: 'Separate multiple emails with a semi-colon ( ; )'
    },
    {
      type: 'input-text',
      name: 'api_access_token',
      label: 'API Access Token',
      description: (
        <a
          href={`${ac?.DomainHelper?.getBaseUrl()}/admin/api-docs`}
          target="_blank"
        >
          API Documentation
        </a>
      ),
      condition: () => ac.AuthHelper.checkFeature('API')
    },
    {
      type: 'input-text',
      name: 'verification_token',
      label: 'Verification Token',
      condition: () => ac.AuthHelper.checkFeature('VerifiedService')
    },
    {
      type: 'textarea',
      name: 'twilio_service_numbers',
      label: 'Twilio service numbers',
      label_info:
        'Phone Number. Multiple numbers separated by semicolon or line break. Sending number will be selected randomly.',
      condition: () => ac.AuthHelper.checkFeature('TwilioService')
    },
    {
      type: 'input-text',
      name: 'twilio_service_sid',
      label: 'Twilio service sid',
      placeholder: data?.third_party?.twilio_service_sid
        ? 'SID Configured'
        : undefined,
      canToggle: true,
      condition: () => ac.AuthHelper.checkFeature('TwilioService')
    },
    {
      type: 'input-text',
      name: 'twilio_service_auth_token',
      label: 'Twilio service auth token',
      placeholder: data?.third_party?.twilio_service_auth_token
        ? 'Auth Token Configured'
        : undefined,
      canToggle: true,
      condition: () => ac.AuthHelper.checkFeature('TwilioService')
    },
    {
      type: 'switch',
      name: 'zapier_service_enabled',
      label: 'Zapier Service: Enabled?',
      label_info:
        'Enabling Zapier at this level will enable it globally. It will not be able to be disabled selectively.',
      condition: () => ac.AuthHelper.checkFeature('ZapierService')
    },
    {
      type: 'input-text',
      name: 'zapier_service_url',
      label: 'Zapier Service: URL',
      condition: () => ac.AuthHelper.checkFeature('ZapierService')
    },
    /* NYI
    {
      type: 'input-text',
      name: 'zoom_service_api_key',
      label: 'Zoom: API Key'
    },
    {
      type: 'input-text',
      name: 'zoom_service_api_secret',
      label: 'Zoom: API Secret'
    },
    */
    {
      type: 'input-text',
      name: 'portable_north_pole_service_username',
      label: 'Portable North Pole: API Username',
      condition: () => ac.AuthHelper.checkFeature('PortableNorthPoleService')
    },
    {
      type: 'input-text',
      name: 'portable_north_pole_service_password',
      label: 'Portable North Pole: API Password',
      condition: () => ac.AuthHelper.checkFeature('PortableNorthPoleService')
    },
    {
      type: 'switch',
      name: 'lock_no_shows',
      label: 'Lock no shows',
      label_info:
        'Enabling this option will cause all orders with a reservation date in the past to be locked every day at midnight. Unlocking requires Studio Managers to do so via the Order itself.'
    },
    {
      type: 'switch',
      name: 'appointment_aware_refunding',
      label: 'Appointment aware refunding',
      label_info:
        'Enabling this option will add an "automatic" partial refund button based on the breakdown below.'
    },
    {
      type: 'group',
      label: 'Appointment aware refund schedule',
      label_info:
        'If no 0 hour value set and/or no limit applies, the Appointment aware refunding button will be disabled.',
      fields: [
        {
          type: 'input-percent',
          name: 'appointment_aware_refunding_percentage_0'
        },
        {
          type: 'html',
          html: <span>&gt;=</span>
        },
        {
          type: 'input-number',
          name: 'appointment_aware_refunding_limit_0',
          placeholder: 'hours'
        },
        {
          type: 'html',
          html: <span>hours</span>
        }
      ]
    },
    {
      type: 'group',
      fields: [
        {
          type: 'input-percent',
          name: 'appointment_aware_refunding_percentage_1'
        },
        {
          type: 'html',
          html: <span>&gt;=</span>
        },
        {
          type: 'input-number',
          name: 'appointment_aware_refunding_limit_1'
        },
        {
          type: 'html',
          html: <span>hours</span>
        }
      ]
    },
    {
      type: 'group',
      fields: [
        {
          type: 'input-percent',
          name: 'appointment_aware_refunding_percentage_2'
        },
        {
          type: 'html',
          html: <span>&gt;=</span>
        },
        {
          type: 'input-number',
          name: 'appointment_aware_refunding_limit_2'
        },
        {
          type: 'html',
          html: <span>hours</span>
        }
      ]
    },
    {
      type: 'group',
      fields: [
        {
          type: 'input-percent',
          name: 'appointment_aware_refunding_percentage_3'
        },
        {
          type: 'html',
          html: <span>&gt;=</span>
        },
        {
          type: 'input-number',
          name: 'appointment_aware_refunding_limit_3'
        },
        {
          type: 'html',
          html: <span>hours</span>
        }
      ]
    },
    {
      type: 'group',
      fields: [
        {
          type: 'input-percent',
          name: 'appointment_aware_refunding_percentage_4'
        },
        {
          type: 'html',
          html: <span>&gt;=</span>
        },
        {
          type: 'input-number',
          name: 'appointment_aware_refunding_limit_4'
        },
        {
          type: 'html',
          html: <span>hours</span>
        }
      ]
    }
  ];
}
