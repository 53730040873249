import { useState, useEffect } from 'react'

/* START TEAMS */
import {
  Autocomplete,
  Backdrop, Box, Button,
  CircularProgress,
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
  TextField, Typography,
  Skeleton
} from '@mui/material';
import Pagination from '../../components/Pagination';
/* END TEAMS */
import MarketTeamCard from '../../components/MarketTeamCard';

import ControlBar from '../../components/ControlBar'
import CopyButton from '../../components/CopyButton'
import FetchHelper from '../../helpers/fetch'
import FieldSelectorComponent from '../../components/FieldSelector'
import FormComponent from '../../components/Form'
import ImportantDates from '../../components/ImportantDates'
import PageHeaderComponent from '../../components/PageHeader'
import TwoSidedMultiselectPaginated from '../../components/TwoSidedMultiselectPaginated'

import PromotionalCouponsPartial from './partials/PromotionalCoupons'
import ProofingPartial from './partials/Proofing'
import SchedulingPartial from './partials/Scheduling'

import UtilsHelper from '../../helpers/utils'
import RouteHelper from '../../helpers/route'

import useAppContext from '../../hooks/useAppContext'
import useNotices from '../../hooks/useNotices'
import useTabs from '../../hooks/useTabs'

import { COUNTRIES } from '../../sys/constants/Countries'

import './edit.css'
import { getGeneralFormFieldsEdit, getLateOrdersFormFields, getMarketingFormFields, getOptionsFormFields, getProofingFormFields } from './providers/form-fields'
import useIsFirstRender from '../../hooks/useIsFirstRender'
import { useParams } from 'react-router-dom'

const MarketsEdit = () => {
    const params = useParams()
    const [ac] = useAppContext()

    const [NoticeComponent, notices] = useNotices('Markets/Markets/Edit')

    const [errors, setErrors] = useState()

    const [data, setData] = useState()
    const [dataMain, setDataMain] = useState()
    const [dataImportantDates, setDataImportantDates] = useState()
    const [dataPersonnelAccess, setDataPersonnelAccess] = useState()
    const [dataCouponCodes, setDataCouponCodes] = useState()
    const [dataLateOrders, setDataLateOrders] = useState()
    const [dataCustomFields, setDataCustomFields] = useState()
    const [dataScheduling, setDataScheduling] = useState()
    const [dataTeams, setDataTeams] = useState();
    const [dataTeamsTags, setDataTeamsTags] = useState();
    const [dataPromotionalCoupons, setDataPromotionalCoupons] = useState()
    const [dataMarketingOptions, setDataMarketingOptions] = useState()
    const [dataOptions, setDataOptions] = useState()

    const [suggestionsTags, setSuggestionsTags] = useState([])
    const [suggestionsLocationGroups, setSuggestionsLocationGroups] = useState([])
    const [suggestionsMarketGroups, setSuggestionsMarketGroups] = useState([])

    const [isOutOfEditFlow, setIsOutOfEditFlow] = useState(false)
    const [doesPageLiveUpdates, setDoesPageLiveUpdates] = useState(false)

    const [tabs, tab, setTab, TabsComponent, tabPropsByTab, setTabsFormsErrors] = useTabs('General', ['General', 'Options', 'Personnel Access', 'Important Dates', 'Late Orders', 'Coupon Codes', 'Fields', 'Marketing', 'Scheduling', 'Teams/Groups', 'Proofing'], 'tab')
    const [tabsMarketing, tabMarketing, setTabMarketing, _, tabPropsByTabMarketing, setTabsFormsErrorsMarketing] = useTabs('Options', ['Options', 'Promo', 'QR', 'HTML'])

    let [generalFormFields, setGeneralFormFields] = useState(getGeneralFormFieldsEdit())
    let [lateOrdersFormFields] = useState(getLateOrdersFormFields())
    let [marketingOptionsFormFields] = useState(getMarketingFormFields())
    let [optionsFormFields] = useState(getOptionsFormFields())
    let [proofingFormFields, setProofingFormFields] = useState(getProofingFormFields())

    const isFirstRender = useIsFirstRender()
    const fetchTeams = () => {
        ac.showSpinner(true)

        FetchHelper({
          url: `/api/v2/admin/markets/${params.id}/teams?q=${dataMarketTeamsSearchTerm}&page=${dataMarketTeamsPage}`
        }, (res) => {
          setDataTeams(res.body)
        }, (res) => { })

        FetchHelper({
          url: `/api/v2/admin/markets/${params.id}/teams/tags`
        }, (res) => {
          ac.showSpinner(false)

          setDataTeamTags(res.body)
        }, (res) => {
          ac.showSpinner(false)
        });
    };

    useEffect(() => {
        let fields = getGeneralFormFieldsEdit(
            suggestionsTags,
            ac.AuthHelper?.settings?.third_party?.centrics_service_enabled,
            ac.AuthHelper?.settings?.sys?.currencies,
            ac.AuthHelper?.settings?.sys?.timezones,
            dataMain
        )
        setGeneralFormFields(fields)
    }, [suggestionsTags, ac.AuthHelper?.settings?.sys, ac.AuthHelper?.settings?.third_party?.centrics_service_enabled, dataMain])

    useEffect(() => {
        if (isFirstRender) return;

        let tabsWithFormsInfoMarketing = [
            {
                tab: 'Options',
                forms: [{ id: 'Main', fields: marketingOptionsFormFields }]
            }
        ]
        setTabsFormsErrorsMarketing(tabsWithFormsInfoMarketing, errors)

        let tabsWithFormsInfo = [
            {
                tab: 'General',
                forms: [{ id: 'Main', fields: generalFormFields }]
            },
            {
                tab: 'Late Orders',
                forms: [{ id: 'Main', fields: lateOrdersFormFields }]
            },
            {
                tab: 'Marketing',
                forms: tabsWithFormsInfoMarketing.map(tabWithFormsInfo => {
                    return tabWithFormsInfo.forms.map(form => {
                        return {
                            ...form,
                            id: tabWithFormsInfo.tab + '_' + form.id
                        }
                    })
                }).flat()
            },
            {
                tab: 'Options',
                forms: [{ id: 'Main', fields: optionsFormFields }]
            },
            {
                tab: 'Proofing',
                forms: [{ id: 'Main', fields: proofingFormFields }]
            }
        ];
        setTabsFormsErrors(tabsWithFormsInfo, errors)
        
    }, [errors])

    useEffect(() => {
        switch(tab) {
            case 'Coupon Codes':
            case 'Fields':
            case 'Important Dates':
            case 'Proofing':
                setDoesPageLiveUpdates(true)
                break;

            case 'Marketing':
                switch (tabMarketing) {
                    case 'QR':
                    case 'HTML':
                        setDoesPageLiveUpdates(true)
                        break;
                    default:
                        setDoesPageLiveUpdates(false)
                }
                break;

            case 'Teams/Groups':
                setDoesPageLiveUpdates(true);

                if (!dataTeams)
                    fetchTeams();

                break;

            default:
                setDoesPageLiveUpdates(false)
        }
    }, [tab, tabMarketing])

    useEffect(() => {
        ac.showSpinner(true)

        FetchHelper({
            url: `/api/v2/admin/markets/${params.id}`
        }, (res) => {
            ac.showSpinner(false)

            let body = res.body

            setData(body)

            setDataMain({
                name: body.name,
                tags: body.tags,
                favicon: body.favicons.fav_16,
                location_group_image_attributes: body.location_group_image_attributes,
                location_group_image: body.location_group_image_url,
                external_id: body.external_id,
                centrics_partner_id: body.centrics_partner_id,
                location_group_id: body.location_group_id,
                market_group_id: body.market_group_id,
                type: body.type,
                purchase_format: body.purchase_format,
                fee_format: body.fee_format,
                contact_emails: UtilsHelper.stringifyEmails(body.contact_emails),
                tax: body.tax,
                snh: body.snh,
                snh_taxable: body.snh_taxable,
                fee_adjustment: body.fee_adjustment,
                fee_taxable: body.fee_taxable,
                address1: body.address.address1,
                address2: body.address.address2,
                city: body.address.city,
                state_province: body.address.state_province,
                country: body.address.country,
                postal_code: body.address.postal_code,
                currency_code: body.currency_code,
                special_instructions: body.special_instructions,
                starts_at: body.starts_at?.replace(/([-+]\d{1,2}:\d{1,2})$/, ''),
                ends_at: body.ends_at?.replace(/([-+]\d{1,2}:\d{1,2})$/, ''),
                timezone: body.timezone,
                phone_number: body.phone_number,
                website: body.website,
                logo_attributes: body.logo_attributes,
                logo: body.logo_url,
                package_plan_id: body.package_plan_id,
                marketing_plan_id: body.marketing_plan_id,
                color_scheme_id: body.color_scheme_id,
                payment_gateway_id: body.payment_gateway_id,
                vanity_domain_id: body.vanity_domain_id
            })

            setDataPersonnelAccess(body.users)

            setDataPromotionalCoupons(body.coupons?.data || [])

            setDataLateOrders({
                late_order_date: body.late_order_date?.replace(/(-\d{1,2}:\d{1,2})|(Z)$/, ''),
                late_order_package_plan_id: body.late_order_package_plan_id,
                late_order_marketing_plan_id: body.late_order_marketing_plan_id,
                late_order_shipping: body.late_order_shipping,
                late_order_fee: body.late_order_fee,
                notify_on_late_order: body.notify_on_late_order,
                customer_service_email: UtilsHelper.stringifyEmails(body.customer_service_emails)
            })

            let importantDatesData = {
                importantDates: body.important_dates?.data,
                showImportantDates: body.options?.show_important_dates,
                marketID: params.id
            }
            setDataImportantDates(importantDatesData)

            setDataCouponCodes(body.coupon_codes?.data)

            setDataCustomFields(body.fields?.data)

            setDataMarketingOptions({
                facebook_enabled: body.options?.facebook_enabled,
                ga_tracking_id: body.analytics?.ga_tracking_id,
                gtm_container_id: body.analytics?.gtm_container_id,
                facebook_pixel_id: body.analytics?.facebook_pixel_id,
                tiktok_pixel_id: body.analytics?.tiktok_pixel_id
            })

            setDataOptions({
                block_mobile: body.options?.block_mobile,
                hide_from_listing: body.options?.hide_from_listing,
                hide_from_postal_code_search: body.options?.hide_from_postal_code_search,
                display_name_during_customer_experience: body.options?.display_name_during_customer_experience,
                collect_shipping: body.options?.collect_shipping,
                email_collection_method: body.options?.email_collection_method,
                email_collection_default: body.options?.email_collection_default,
                email_collection_message: body.options?.email_collection_message,
                email_friendly_from: body.options?.email_friendly_from,
                self_service_enabled: body.options?.self_service_enabled,
                secret_code: body.secret_code,
                market_confirmation_message: body.market_confirmation_message
            })
        }, (res) => {
            ac.showSpinner(false)
        })

        FetchHelper({
            url: '/api/v2/admin/markets/tags'
        }, (res) => {
            setSuggestionsTags(res.body)
        }, (res) => {

        })

        FetchHelper({
            url: '/api/v2/admin/location-groups'
        }, (res) => {
            setSuggestionsLocationGroups(res.body.data.map((item) => {
                return {
                    value: item.name,
                    title: item.name,
                    item: item
                }
            }))
        }, (res) => {

        })

        FetchHelper({
            url: '/api/v2/admin/market-groups'
        }, (res) => {
            setSuggestionsMarketGroups(res.body.data.map((item) => {
                return {
                    title: item.name,
                    value: item.name,
                    item: item
                }
            }))
        }, (res) => {

        })
    }, [])

    /* START TEAMS */
    const [dataMarketTeamsPage, setDataMarketTeamsPage] = useState(1);
    const [dataMarketTeamsSearchTerm, setDataMarketTeamsSearchTerm] = useState('');
    const [dataMarketTeamsAddDialogOpen, setDataMarketTeamsAddDialogOpen] = useState(false);
    const [dataMarketTeamsAddDialogLoading, setDataMarketTeamsAddDialogLoading] = useState(false);
    const [dataMarketTeamsAddDialogErrors, setDataMarketTeamsAddDialogErrors] = useState();

    const handleMarketTeamsAddDialogClose = () => {
      setDataMarketTeamsAddDialogOpen(false);
      setDataMarketTeamsAddDialogErrors(undefined);
    };
    const handleMarketTeamsAddDialogSubmit = (e) => {
      e.preventDefault();
      
      setDataMarketTeamsAddDialogLoading(true);
  
      const formData = new FormData(e.currentTarget);
      const formJson = Object.fromEntries(formData.entries());

      FetchHelper({
        url: `/api/v2/admin/markets/${params.id}/teams`,
        method: 'POST',

        body: {
          market_team: formJson
        }
      }, (res) => {
        setDataMarketTeamsAddDialogLoading(false);

        handleMarketTeamsAddDialogClose();

        fetchTeams();
      }, (res) => {
        setDataMarketTeamsAddDialogLoading(false);

        setDataMarketTeamsAddDialogErrors(res.body);
      });
    };
    const handleMarketTeamChange = () => { fetchTeams(); };

    const renderMarketTeamsAddDialog = () => {
      return (
        <Dialog
          open={dataMarketTeamsAddDialogOpen}
          onClose={handleMarketTeamsAddDialogClose}
          PaperProps={{
            component: 'form',
            onSubmit: handleMarketTeamsAddDialogSubmit
          }}
        >
          <DialogTitle>Add Team/Group</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Add Team/Group lorem ipsum
            </DialogContentText>
          <Box sx={{ mt: 2 }}>
            <TextField id="market--market-teams-add-dialog-name" fullWidth required label="Name" name="name" disabled={dataMarketTeamsAddDialogLoading} error={dataMarketTeamsAddDialogErrors?.hasOwnProperty('name')} helperText={dataMarketTeamsAddDialogErrors?.name?.join(<br />)} />
            <TextField id="market--market-teams-add-dialog-division" sx={{ my: 2 }} fullWidth required label="Division" name="division" disabled={dataMarketTeamsAddDialogLoading} error={dataMarketTeamsAddDialogErrors?.hasOwnProperty('division')} helperText={dataMarketTeamsAddDialogErrors?.division?.join(<br />)} />
            <Autocomplete
              id="market--market-teams-add-dialog-sport-type--autocomplete"
              fullWidth
              options={[
                { label: 'Baseball', id: 'Baseball' },
                { label: 'Basketball', id: 'Basketball' },
                { label: 'Golf', id: 'Golf' },
                { label: 'Soccer', id: 'Soccer' }
              ]}
              renderInput={(params) => <TextField {...params} required label="Sport Type" name="sport_type" error={dataMarketTeamsAddDialogErrors?.hasOwnProperty('sport_type')} helperText={dataMarketTeamsAddDialogErrors?.sport_type?.join(<br />)} />}
              disabled={dataMarketTeamsAddDialogLoading}
            />
          </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleMarketTeamsAddDialogClose} disabled={dataMarketTeamsAddDialogLoading}>Cancel</Button>
            <Button type="submit" loading={dataMarketTeamsAddDialogLoading}>Add</Button>
          </DialogActions>
        </Dialog>
      );
    };

    useEffect(() => { fetchTeams(); }, [dataMarketTeamsPage]);
    /* END TEAMS */

    const handleSubmit = () => {
        ac.showSpinner(true)

        let body = dataMain

        let combinedBody = {
            name: body.name,
            tag_names: body.tags,
            partner_id: body.partner_id,
            centrics_partner_id: body.centrics_partner_id,
            location_group_id: body.location_group_id,
            market_group_id: body.market_group_id,
            type: body.type,
            purchase_format: body.purchase_format,
            fee_format: body.fee_format,
            contact_email: UtilsHelper.stringifyEmails(body.contact_emails),
            tax: body.tax,
            snh: body.snh,
            snh_taxable: body.snh_taxable,
            fee_adjustment: body.fee_adjustment,
            fee_taxable: body.fee_taxable,
            address_attributes: {
                address1: body.address1,
                address2: body.address2,
                city: body.city,
                state_province: body.state_province,
                country: body.country,
                postal_code: body.postal_code,
            },
            currency_code: body.currency_code,
            special_instructions: body.special_instructions,
            starts_at: body.starts_at,
            ends_at: body.ends_at,
            timezone: body.timezone,
            phone_number: body.phone_number,
            website: body.website,
            package_plan_id: body.package_plan_id,
            marketing_plan_id: body.marketing_plan_id,
            color_scheme_id: body.color_scheme_id,
            payment_gateway_id: body.payment_gateway_id,
            vanity_domain_id: body.vanity_domain_id,
            user_ids: body.user_ids,
            late_order_date: dataLateOrders.late_order_date,
            late_order_package_plan_id: dataLateOrders.late_order_package_plan_id,
            late_order_marketing_plan_id: dataLateOrders.late_order_marketing_plan_id,
            late_order_shipping: dataLateOrders.late_order_shipping,
            late_order_fee: dataLateOrders.late_order_fee,
            notify_on_late_order: dataLateOrders.notify_on_late_order,
            customer_service_email: UtilsHelper.stringifyEmails(dataLateOrders.customer_service_email),
            options_attributes: {}
        }

        if (combinedBody.notify_on_new_proof !== true) {
            delete combinedBody.new_proof_notification_text_template_id
            delete combinedBody.new_proof_notification_email_template_id
        }

        if (combinedBody.notify_on_proof_image_change !== true) {
            delete combinedBody.proof_image_change_notification_text_template_id
            delete combinedBody.proof_image_change_notification_email_template_id
        }

        if (body.favicon?.value) {
            combinedBody.favicon_base64 = body.favicon.value
        }

        if (body.location_group_image?.value) {
            combinedBody.location_group_image_attributes = {
                data_base64: body.location_group_image.value
            }
        }

        if (body.logo?.value) {
            combinedBody.logo_attributes = {
                data_base64: body.logo.value
            }
        }

        if (dataScheduling) {
            combinedBody = {
                ...combinedBody,
                scheduling_extra_text: dataScheduling.scheduling_extra_text,
                scheduling_start_on_current_month: dataScheduling.scheduling_start_on_current_month,
                scheduling_rescheduling_disabled: dataScheduling.scheduling_rescheduling_disabled,
                scheduling_policy: dataScheduling.scheduling_policy
            }

            combinedBody.options_attributes.scheduling_use_legacy_timeslot_display = dataScheduling.scheduling_use_legacy_timeslot_display
            combinedBody.options_attributes.use_scheduling = dataScheduling.use_scheduling

            if (dataScheduling.reservation_time_limit) {
                combinedBody.options_attributes.reservation_time_limit = dataScheduling.reservation_time_limit
            }

            if (dataScheduling.walkup_time_limit) {
                combinedBody.options_attributes.walkup_time_limit = dataScheduling.walkup_time_limit
            }
        }

        if (dataPersonnelAccess?.user_ids) {
            combinedBody.user_ids = dataPersonnelAccess.user_ids
        }

        if (dataPromotionalCoupons.length) {
            combinedBody.market_coupons_attributes = dataPromotionalCoupons.map((item, index) => {
                item.position = index

                return item
            })
        }

        if(dataMarketingOptions) {
            combinedBody.options_attributes = {
                ...combinedBody.options_attributes,
                facebook_enabled: dataMarketingOptions.facebook_enabled
            }
            combinedBody.ga_tracking_id = dataMarketingOptions.ga_tracking_id
            combinedBody.gtm_container_id = dataMarketingOptions.gtm_container_id
            combinedBody.facebook_pixel_id = dataMarketingOptions.facebook_pixel_id
            combinedBody.tiktok_pixel_id = dataMarketingOptions.tiktok_pixel_id
        }

        if(dataOptions) {
            combinedBody.options_attributes = {
                ...combinedBody.options_attributes,
                block_mobile: dataOptions.block_mobile,
                hide_from_listing: dataOptions.hide_from_listing,
                hide_from_postal_code_search: dataOptions.hide_from_postal_code_search,
                display_name_during_customer_experience: dataOptions.display_name_during_customer_experience,
                collect_shipping: dataOptions.collect_shipping,
                email_collection_method: dataOptions.email_collection_method,
                email_collection_default: dataOptions.email_collection_default,
                email_collection_message: dataOptions.email_collection_message,
                email_friendly_from: dataOptions.email_friendly_from,
                self_service_enabled: dataOptions.self_service_enabled
            }

            combinedBody.secret_code = dataOptions.secret_code
            combinedBody.market_confirmation_message = dataOptions.market_confirmation_message
        }

        FetchHelper({
            url: `/api/v2/admin/markets/${params.id}`,
            method: 'put',
            body: {
                market: combinedBody
            }
        }, (res) => {
            ac.showSpinner(false)
            RouteHelper.redirect('/markets')
        }, (res) => {
            ac.showSpinner(false)

            if (res.status === 422) {
                let errors = UtilsHelper.preProcess422Errors(
                    [...generalFormFields, ...lateOrdersFormFields, ...marketingOptionsFormFields, ...optionsFormFields],
                    res.body
                )
                setErrors(errors)
                UtilsHelper.scrollTop()
            }
        })
    }

    const renderMarketing = (tab) => {
        switch (tab) {
            case 'Options':
                return (
                    <FormComponent formData={dataMarketingOptions} formErrors={errors} setFormErrors={setErrors} onChange={setDataMarketingOptions} displayErrorBanner={true} fields={marketingOptionsFormFields} />
                )
            case 'Promo':
                return (
                    <div className="marketing-tab-container">
                        <PromotionalCouponsPartial value={dataPromotionalCoupons} onChange={(value) => setDataPromotionalCoupons(value)} />
                    </div>
                )
            case 'QR':
                return (
                    <div className="marketing-tab-container">
                        <div className="qr-code text-center">
                            <img className='m-auto' src={data.qr_url} />
                            <p><a href={data.short_url} target="_blank">{data.short_url}</a></p>
                        </div>
                    </div>
                )
            case 'HTML':
                return (
                    <div className='marketing-tab-container'>
                         <div className="markets-edit-section">
                            <h3><span>Market URL</span> <CopyButton value={data?.sale_analytics_url} showInput={false} /></h3>
                            <code><pre>{data?.sale_analytics_url}</pre></code>
                        </div>

                        <div className="markets-edit-section mt-5">
                            <h3><span>Marketing Page</span> <CopyButton value={data?.snippets?.marketing} showInput={false} /></h3>
                            <code><pre>{data?.snippets?.marketing}</pre></code>
                        </div>
                        
                        <div className="markets-edit-section mt-5">
                            <h3><span>Packages Page</span> <CopyButton value={data?.snippets?.packages} showInput={false} /></h3>
                            <code><pre>{data?.snippets?.packages}</pre></code>
                        </div>
                    </div>
                )
        }
    }

    return (
        <div className="markets-edit-page-container">
            <PageHeaderComponent title={`Edit Market ${data?.name ? `— ${data.name}` : ''}`} />

            <TabsComponent tabs={tabs} tab={tab} setTab={setTab} tabPropsByTab={tabPropsByTab} />
            {!data ? null : (
                <>
                    {tab === 'General' ?
                        <>
                            <NoticeComponent location="Tab/General" notices={notices} />
                            <FormComponent formData={dataMain} formErrors={errors} setFormErrors={setErrors} onChange={setDataMain} displayErrorBanner={true} fields={generalFormFields} />
                        </>
                        : null}
                    {tab === 'Personnel Access' ?
                        <>
                            <NoticeComponent location="Tab/ImportantDates" notices={notices} />

                            <div className="global-section">
                                <TwoSidedMultiselectPaginated apiUrl="/api/v2/admin/users" queryParams="type=Personnel" value={dataPersonnelAccess} renderTitle={(item) => `${item.full_name} (${item.login})`} tabOptions={['Personnel']} itemType="users" onChange={(items) => {
                                    let user_ids = items.map((item, index) => {
                                        return item.id
                                    })

                                    setDataPersonnelAccess({ ...dataPersonnelAccess, user_ids })
                                }} />
                            </div>
                        </>
                        : null}
                    {tab === 'Important Dates' ?
                        <>
                            <NoticeComponent location="Tab/ImportantDates" notices={notices} />
                            <ImportantDates defaults={dataImportantDates} sync={true} syncUrl={data.important_dates.url} onChange={(data) => setDataImportantDates(data)} />
                        </>
                        : null}
                    {tab === 'Late Orders' ?
                        <>
                            <NoticeComponent location="Tab/LateOrders" notices={notices} />
                            <FormComponent formData={dataLateOrders} formErrors={errors} setFormErrors={setErrors} onChange={setDataLateOrders} displayErrorBanner={true} fields={lateOrdersFormFields} />
                        </>
                        : null}
                    {tab === 'Coupon Codes' ?
                        <>
                            <NoticeComponent location="Tab/CouponCodes" notices={notices} />
                            <FieldSelectorComponent sync={true} sortable={false} syncURL={data.coupon_codes?.url} apiPrefix="coupon_code" buttonTitle="Coupon Code" data={dataCouponCodes} apiURL="/api/v2/admin/coupon-codes" editUrl={(id) => `/coupon-codes/${id}/edit`} onChange={(data) => setDataCouponCodes(data)} />
                        </>
                        : null}
                    {tab === 'Fields' ?
                        <>
                            <NoticeComponent location="Tab/Fields" notices={notices} />
                            <FieldSelectorComponent sync={true} syncURL={data.fields?.url} apiPrefix="field" buttonTitle="Field" data={dataCustomFields} apiURL="/api/v2/admin/fields" editUrl={(id) => `/fields/${id}/edit`} onChange={(data) => setDataCustomFields(data)} />
                        </>
                        : null}
                    {tab === 'Marketing' ?
                        <>
                            <TabsComponent tabs={tabsMarketing} tab={tabMarketing} setTab={setTabMarketing} tabPropsByTab={tabPropsByTabMarketing}/>
                            <NoticeComponent location="Tab/Marketing" notices={notices} />
                            { renderMarketing(tabMarketing) }
                        </>
                        : null}
                    {tab === 'Scheduling' ?
                        <>
                            <SchedulingPartial entityData={data} onChange={(combinedData) => {
                                setDataScheduling(combinedData)
                            }} onTabChange={(tabChangeTab) => {
                                switch (tabChangeTab) {
                                    case 'Calendar':
                                    case 'Copy':
                                        setIsOutOfEditFlow(true)
                                        setDoesPageLiveUpdates(true)
                                        break
                                    default:
                                        setIsOutOfEditFlow(false)
                                        setDoesPageLiveUpdates(false)
                                        break
                                }
                            }} />
                        </>
                    : null}
                    {tab === 'Proofing' ?
                        <>
                            <NoticeComponent location="Tab/Proofing" notices={notices} />
                            <ProofingPartial entityData={data} marketErrors={errors} setMarketErrors={setErrors}
                                proofingFormFields={proofingFormFields} setProofingFormFields={setProofingFormFields} />
                        </>
                    : null}
                    {tab === 'Teams/Groups' ?
                        <>
                            <NoticeComponent location="Tab/Teams+Groups" notices={notices} />
                            <Box id="market--market-teams-container" className="flex flex-col gap-4 mx-8 p-8 bg-white">
                              {dataTeams ? (
                                <>
                                  {renderMarketTeamsAddDialog()}
                                  <Box className="flex justify-between">
                                    <Box>
                                      <Button onClick={() => { setDataMarketTeamsAddDialogOpen(true) }}>Add Team</Button>
                                    </Box>
                                    <Box>
                                      {console.log(dataTeams)}
                                      <Pagination total={dataTeams.total} currentPage={dataTeams.current_page > dataTeams.total_pages ? dataTeams.total_pages : dataTeams.current_page} totalPages={dataTeams.total_pages} setPage={setDataMarketTeamsPage} />
                                    </Box>
                                  </Box>
                                  <Box className="flex flex-wrap gap-4">
                                    {dataTeams.data.map((team) => <MarketTeamCard key={`market-team--${team.id}`} dataTeam={team} onDelete={handleMarketTeamChange} onUpdate={handleMarketTeamChange} />)}
                                  </Box>
                                </>
                              ) : (
                                <>
                                  <Box className="flex justify-between">
                                    <Skeleton width={100} height={37} variant="rectangular" />
                                    <Skeleton width={270} height={37} variant="rectangular" />
                                  </Box>
                                  <Box className="flex flex-wrap gap-4">
                                    <Skeleton className="flex-1 min-w-[750px]" height={270} variant="rectangular" />
                                    <Skeleton className="flex-1 min-w-[750px]" height={270} variant="rectangular" />
                                    <Skeleton className="flex-1 min-w-[750px]" height={270} variant="rectangular" />
                                  </Box>
                                </>
                              )}
                            </Box>
                        </>
                    : null}
                    {tab === 'Options' ?
                        <>
                            <NoticeComponent location="Tab/Options" notices={notices} />
                            <FormComponent formData={dataOptions} formErrors={errors} setFormErrors={setErrors} onChange={setDataOptions} displayErrorBanner={true} fields={optionsFormFields} />
                        </>
                        : null}
                </>
            )}
            <ControlBar baseApiUrl="/markets" redirectUrl="/markets" enableCopy={false} cancelUrl="/markets" data={data} handleSubmit={handleSubmit} isOutOfEditFlow={isOutOfEditFlow}
                doesPageLiveUpdates={doesPageLiveUpdates}/>
        </div>
    )
}

export default MarketsEdit
