import { useEffect, useRef, useState } from 'react';
import { useConfirm } from 'material-ui-confirm';
import {
  Autocomplete,
  Box, Button,
  Card, CardContent, CardMedia, CardActionArea, CardActions,
  Divider,
  IconButton,
  Tab,
  TextField,
  Typography
} from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import {
  DeleteOutline as DeleteOutlineIcon,
  EditOutlined as EditOutlineIcon
} from '@mui/icons-material';

import CRUDDialBar from '../CRUDDialBar';
import FetchHelper from '../../helpers/fetch';
import useAppContext from '../../hooks/useAppContext';


export default function({ dataTeam, onUpdate, onDelete, elevation = 4 }) {
  const teamForm = useRef();
  const confirm = useConfirm();

  const [dataColors, setDataColors] = useState();
  const [dataSponsors, setDataSponsors] = useState();
  const [dataStaff, setDataStaff] = useState();
  const [dataTags, setDataTags] = useState();

  const [dataEditMode, setDataEditMode] = useState(false);
  const [dataEditModeErrors, setDataEditModeErrors] = useState();
  const [dataEditModeLoading, setDataEditModeLoading] = useState(false);

  const [dataTab, setDataTab] = useState('0');

  const [ac] = useAppContext();

  const handleDelete = async () => {
    const { confirmed } = await confirm({ description: '[PLACEHOLDER] Are you sure?' });

    if (!confirmed) return;

    ac.showSpinner(true);

    FetchHelper({
      url: dataTeam.url,
      method: 'DELETE',
    }, (res) => {
      if (!onDelete) return;

      onDelete(dataTeam);
    }, (res) => {
      if (onDelete) return;

      ac.showSpinner(false);
    });
  };

  const handleSubmit = () => {
    setDataEditModeLoading(true);

    const formData = new FormData(teamForm.current);
    const formJSON = Object.fromEntries(formData.entries());

    FetchHelper({
      url: dataTeam.url,
      method: 'PUT',
      body: {
        team: formJSON
      }
    }, (res) => {
      if (!onUpdate) return;

      onUpdate(dataTeam);

      setDataEditMode(false);
      setDataEditModeLoading(false);
    }, (res) => {
      if (onUpdate) return;

      setDataEditModeLoading(false);
    });
  };

  const handleEditModeDisable = () => {
    setDataEditMode(false);
  };

  const handleEditModeEnable = () => {
    setDataEditMode(true);
  };

  const handleTabChange = (e, newTab) => setDataTab(newTab);

  useEffect(() => {
    setDataColors(dataTeam.colors.data)
    setDataSponsors(dataTeam.sponsors.data)
    setDataStaff(dataTeam.staff.data)

    setDataTags(dataTeam.tags)
  }, []);

  return (
    <Card sx={{ borderColor: 'primary.main', border: '5px solid black' }} className="flex flex-1 min-w-[750px]" elevation={elevation}>
      <Box className="flex flex-col">
        <Box className="w-[200px]">
        <CardMedia
          component="img"
          sx={{ height: 200, width: 200, mx: 'auto', p: 1, borderRight: '5px solid black', borderBottom: '5px solid black' }}
          image={`data:image/svg+xml;base64,${dataTeam?.barcode?.svg}`}
          alt={`barcode for ${dataTeam?.name}`}
        />
        </Box>
        <Box className="flex flex-grow items-center">
          {dataTeam?.number?.split('').map((digit) => {
            return (
              <Typography component="div" align="center" className="font-bold flex-grow" sx={{ fontSize: '3.25rem', lineHeight: '3.75rem' }}>
                {digit}
              </Typography>
            );
          })}
        </Box>
      </Box>
      <Box className="flex flex-col flex-grow">
        <TabContext value={dataTab}>
        <CardContent className="h-full">
          <form ref={teamForm}>
          {!dataEditMode ?
            <Typography variant="h4" component="span">{dataTeam?.name}</Typography> :
            <TextField fullWidth required defaultValue={dataTeam.name} name="name" label="Name" disabled={dataEditModeLoading} error={dataEditModeErrors?.hasOwnProperty('name')} helperText={dataEditModeErrors?.name?.join(<br />)} />}
          <Box className="flex gap-4 justify-between" sx={dataEditMode ? { mt: 2 } : null}>
            {!dataEditMode ?
              <Typography variant="h6" component="div">{dataTeam.sport_type}</Typography> :
              <Autocomplete
                fullWidth
                options={[
                  { label: 'Baseball', id: 'Baseball' },
                  { label: 'Basketball', id: 'Basketball' },
                  { label: 'Golf', id: 'Golf' },
                  { label: 'Soccer', id: 'Soccer' }
                ]}
                defaultValue={dataTeam.sport_type}
                renderInput={(params) => <TextField {...params} required label="Sport Type" name="sport_type" error={dataEditModeErrors?.hasOwnProperty('sport_type')} helperText={dataEditModeErrors?.sport_type?.join(<br />)} />}
                disabled={dataEditModeLoading}
              />}
            {!dataEditMode ?
              <Typography variant="h6" component="div">{dataTeam?.division}</Typography> :
              <TextField fullWidth required defaultValue={dataTeam.division} name="division" label="Division" disabled={dataEditModeLoading} error={dataEditModeErrors?.hasOwnProperty('division')} helperText={dataEditModeErrors?.division?.join(<br />)} />}
          </Box>
          </form>

          <Divider />

          <TabPanel value="1">
              <CRUDDialBar apiURL={dataTeam?.colors?.url} dataCollection={dataColors} onChange={setDataColors} itemName="Color" encapsulateAs="team_color">
                <TextField
                  autoFocus
                  required
                  margin="dense"
                  id="color-dialog--name"
                  name="name"
                  label="Name"
                  type="text"
                  variant="standard"
                />
                <TextField
                  margin="dense"
                  id="color-dialog--value"
                  name="value"
                  label="Explicit Value"
                  type="text"
                  variant="standard"
                />
              </CRUDDialBar>
          </TabPanel>

          <TabPanel value="2">
              <CRUDDialBar apiURL={dataTeam?.staff?.url} dataCollection={dataStaff} onChange={setDataStaff} itemName="Staff" encapsulateAs="team_staff">
                <TextField
                  autoFocus
                  required
                  margin="dense"
                  id="staff-dialog--name"
                  name="name"
                  label="Name"
                  type="text"
                  variant="standard"
                />
                <TextField
                  margin="dense"
                  id="staff-dialog--value"
                  name="title"
                  label="Title"
                  type="text"
                  variant="standard"
                />
              </CRUDDialBar>
          </TabPanel>

          <TabPanel value="3">
              <CRUDDialBar apiURL={dataTeam?.sponsors?.url} dataCollection={dataSponsors} onChange={setDataSponsors} itemName="Sponsor" encapsulateAs="team_sponsor">
                <TextField
                  autoFocus
                  required
                  margin="dense"
                  id="sponsor-dialog--name"
                  name="name"
                  label="Name"
                  type="text"
                  variant="standard"
                />
              </CRUDDialBar>
          </TabPanel>
        </CardContent>
        <CardActions className={`flex ${dataEditMode ? 'justify-evenly' : 'justify-between'}`}>
          {dataEditMode ? (
            <>
              <Button onClick={handleEditModeDisable} disabled={dataEditModeLoading} color="error">Cancel</Button>
              <Button onClick={handleSubmit} loading={dataEditModeLoading}>Update</Button>
            </>
          ) : (
            <>
              <Box>
                <TabList variant="fullWidth" onChange={handleTabChange}>
                  <Tab label="General" value="0" />
                  <Tab label="Colors" value="1" />
                  <Tab label="Staff" value="2" />
                  <Tab label="Sponsors" value="3" />
                </TabList>
              </Box>
              <Box>
                {onUpdate ? <IconButton color="secondary" onClick={handleEditModeEnable}><EditOutlineIcon /></IconButton> : null}
                {onDelete ? <IconButton color="error" onClick={handleDelete}><DeleteOutlineIcon /></IconButton> : null}
              </Box>
            </>
          )}
        </CardActions>
        </TabContext>
      </Box>
    </Card>
  );
};
