import { Children, cloneElement, useEffect, useState } from 'react';

import {
  Box,
  Button,
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
  Fab,
  Skeleton,
  SpeedDial, SpeedDialAction, SpeedDialIcon,
  Tooltip,
  Typography
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import FetchHelper from '../../helpers/fetch';

export default function({ apiURL, children, dataCollection, encapsulateAs = 'item', itemName = 'item', onChange, size = 'medium' }) {
  const [dataDialogOpen, setDataDialogOpen] = useState(false);
  const [dataDialogEditErrors, setDataDialogEditErrors] = useState();
  const [dataDialogEditMode, setDataDialogEditMode] = useState(false);
  const [dataDialogEditObject, setDataDialogEditObject] = useState();

  const handleDialActionDelete = (item) => {
    FetchHelper({
      url: item.url,
      method: 'DELETE',
    }, (res) => {
      if (!onChange) return;

      onChange(dataCollection.filter((existing) => existing.id !== item.id));
    }, (res) => {
      console.log(`Something went wrong while deleting item`);
    });
  };

  const handleDialActionEdit = (item) => {
    setDataDialogEditMode(true);
    setDataDialogEditObject(item);

    handleDialogOpen();
  };

  const handleDialogOpen = () => {
    setDataDialogOpen(true);
  };
  const handleDialogClose = () => {
    setDataDialogOpen(false);
    setDataDialogEditMode(false);
    setDataDialogEditObject();
  };
  const handleDialogSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget);
    const formJSON = Object.fromEntries(formData.entries());

    FetchHelper({
      url: apiURL,
      method: 'POST',
      body: {
        [encapsulateAs]: formJSON
      }
    }, (res) => {
      if (onChange)
        onChange([...dataCollection, res.body]);

      handleDialogClose();
    }, (res) => {
      setDataDialogEditErrors(res.body);
    });
  };

  const dialActions = [{
    name: 'Edit',
    icon: <EditIcon />,
    onClick: handleDialActionEdit
  }, {
    name: 'Delete',
    icon: <DeleteOutlineIcon />,
    onClick: handleDialActionDelete
  }];

  const decoratedName = (name) => {
    return name.split(' ').map((part) => part[0].toUpperCase()).join('');
  }

  const render = () => {
    return (
      <Box className="flex">
        {renderDialog()}
        <Box className="flex flex-wrap" sx={{ transform: 'translateZ(0px)' }}>
          {renderExistingItems()}
        </Box>
        <Box>
          <Tooltip title={`Add ${itemName}`}>
            <Fab size={size} aria-label={`add ${itemName}`} sx={{ backgroundColor: 'transparent' }} onClick={handleDialogOpen}>
              <AddIcon />
            </Fab>
          </Tooltip>
        </Box>
      </Box>
    );
  };

  const renderChildren = () => {
    if (!dataDialogEditMode) return children;

    const preparedChildren = Children.map(children, (child) => {
      return cloneElement(child, {
        value: dataDialogEditObject[child.props.name] || '',
        onChange: (e) => setDataDialogEditObject({ ...dataDialogEditObject, [child.props.name]: e.target.value })
      });
    });

    return preparedChildren;
  };

  const renderDialog = () => {
    return (
      <Dialog
        open={dataDialogOpen}
        onClose={handleDialogClose}
        PaperProps={{
          component: 'form',
          onSubmit: handleDialogSubmit
        }}
      >
        <DialogTitle>Add {itemName}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Add {itemName} lorem ipsum
          </DialogContentText>
          {renderChildren()}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button type="submit">{dataDialogEditMode ? 'Update' : 'Add'}</Button>
        </DialogActions>
      </Dialog>
    );
  };

  const renderExistingItems = () => {
    let elements = [];

    dataCollection?.forEach((item, idx) => {
      const colorMedian = item.color ? item.color.rgb.reduce((agg, num) => agg + num, 0) : 0;
      const colorSX = item.color ? {
        backgroundColor: `#${item.color.hex}`,
        color: colorMedian > 382.5 ? 'black' : 'white'
      } : {};

      elements.push(<SpeedDial
        ariaLabel={`existing ${itemName} ${item.name} speeddial`}
        FabProps={{
          sx: {
            ...colorSX,
            mr: 1
          },
          size: size,
        }}
        icon={<Typography>{decoratedName(item.name)}</Typography>}
        direction="down"
      >
        {dialActions.map((actionItem) => (
          <SpeedDialAction
            key={`${item.id}--${actionItem.name}`}
            icon={actionItem.icon}
            onClick={() => { actionItem.onClick(item) }}
            tooltipTitle={actionItem.name}
          />
        ))}
      </SpeedDial>);
    });

    return elements;
 };

  return render();
};
